import React, { useContext } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { FlagsContext } from 'src/context';
import { CopilotAdminAuthBackground, white } from 'src/theme/colors';

type StyleProps = {
  GoogleLoginForInternalUser: boolean;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
      },
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        margin: 0,
        // only apply copilot bg color on desktop
        [theme.breakpoints.up('sm')]: {
          backgroundColor: (props: StyleProps) =>
            props.GoogleLoginForInternalUser
              ? CopilotAdminAuthBackground
              : white,
        },
      },
      '#app': {
        height: '100%',
        width: '100%',
      },
    },
    main: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      margin: 'auto',
    },
  }),
);

export const OnboardingPageLayout: React.FC = ({ children }) => {
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const classes = useStyles({
    GoogleLoginForInternalUser,
  });
  return (
    <div className={classes.main}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
