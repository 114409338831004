import React, { useContext } from 'react';
import { ButtonProps, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Button, { ButtonModel } from 'src/components/Button';
import * as Colors from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { PrimaryButtonShadow } from 'src/theme/shadows';
import { FlagsContext } from 'src/context';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxShadow: PrimaryButtonShadow,
      borderRadius: 4,
      border: `1px solid ${Colors.NonHoverBlackBorder}`,
    },
    containedBackground: {
      background: Colors.BlackHeadings,
      transition: 'opacity 100ms ease',
      '&:hover': {
        opacity: '0.9',
      },
      '&:disabled': {
        background: Colors.BlackHeadings,
      },
    },
    submitButton: {
      height: 48,
      width: '100%',
    },
    sharpButton: {
      borderRadius: 0,
      borderColor: Colors.HoverBorder,
    },
  }),
);

const OnboardingButton: React.FC<ButtonProps & ButtonModel> = ({
  children,
  ...otherProps
}) => {
  const classes = useStyles();

  const { GoogleLoginForInternalUser } = useContext(FlagsContext);

  return (
    <Button
      className={clsx(
        classes.root,
        classes.submitButton,
        // add custom backgrounds for mui-buttons where variant is contained
        { [classes.containedBackground]: otherProps.variant === 'contained' },
        { [classes.sharpButton]: GoogleLoginForInternalUser },
      )}
      {...otherProps}
    >
      <BaseTypography fontType="15Medium">{children}</BaseTypography>
    </Button>
  );
};

export default OnboardingButton;
