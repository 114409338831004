import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { I18n } from 'aws-amplify';
import {
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  getIn,
} from 'formik';
import React, { MutableRefObject, useContext } from 'react';
import OnboardingButton from 'src/components/Onboarding/OnboardingButton';
import OnboardingCard from 'src/components/Onboarding/OnboardingCard';
import { OnboardingLoginFormFooterActions } from 'src/components/Onboarding/OnboardingForms/OboardingLoginFormFooterActions';
import { OnboardingButtonContainer } from 'src/components/Onboarding/OnboardingForms/OnboardingButtonContainer';
import { OnboardingFormError } from 'src/components/Onboarding/OnboardingForms/OnboardingFormError';
import OnboardingFormLayout from 'src/components/Onboarding/OnboardingForms/OnboardingFormLayout';
import {
  OnboardingFormProps,
  OnboardingFormValues,
} from 'src/components/Onboarding/OnboardingForms/onboardingFormTypes';
import RowDivider from 'src/components/RowDivider';
import BaseTypography from 'src/components/Text/BaseTypography';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/components/TextField';
import { GoogleAuthButton } from 'src/components/UI/Buttons/GoogleAuthButton';
import { AUTH_STATES } from 'src/constants/authConsts';
import { OnboardingTrackEventName } from 'src/constants/onboardingConsts';
import { FlagsContext, RouteContext } from 'src/context';
import { useAppSelector } from 'src/hooks/useStore';
import { GraySmall } from 'src/theme/colors';
import { UrlUtils } from 'src/utils';
import { TrackEvent } from 'src/utils/AnalyticsUtils';
import { GetPasswordValidation, SignInWithGoogle } from 'src/utils/AuthUtils';
import {
  removeSpaceFromString,
  removeSpaceToLowerCase,
} from 'src/utils/StringUtils';
import * as Yup from 'yup';

export interface OnboardingLoginProps {
  showOnboardingLoginActions?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordInputLabel: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    googleIcon: {
      marginRight: theme.spacing(1),
    },
    onboardingDisabledText: {
      textAlign: 'center',
    },
  }),
);

// TODO(GoogleLoginForInternalUser): remove this when flag is enabled on prod
// In legacy onboarding form the card is wrapping the inputs and
// not the title.
// In the new onboarding layout design the onboarding card
// is the container for the title and the inputs.
export const FormInputsContainer: React.FC = ({ children }) => {
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  return GoogleLoginForInternalUser ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <OnboardingFormLayout>
      <OnboardingCard>{children}</OnboardingCard>
    </OnboardingFormLayout>
  );
};

/**
 * Onboarding login challenge form. This form is used to start the login process for
 * an internal user. This is used in the following scenarios:
 * 1. portal login flow: enter email and password
 * 2. admin login flow: enter email and go through the custom login flow with pass code
 * 3. create portal (onboarding) flow: enter email and password which will either
 * be a new user or existing user. Existing user will have their info prefilled in the
 * create portal flow.
 */
export const OnboardingLoginForm = React.forwardRef(
  (
    {
      initialValues,
      handleSubmitDone,
      showOnboardingLoginActions = false,
      trackedEvents,
      onAnalyticsEventTracked,
    }: OnboardingFormProps & OnboardingLoginProps,
    ref,
  ) => {
    const classes = useStyles();
    const { pathname, query } = React.useContext(RouteContext);
    const isAdminLogin = pathname === '/admin'; // admin login flow
    const isPortalLogin = pathname === '/login' || pathname === '/add-account'; // portal login flow
    const { GoogleLoginForInternalUser, OnboardingDisabledReason } =
      useContext(FlagsContext);
    const isSignUpDisabled = Boolean(
      !isPortalLogin && OnboardingDisabledReason,
    );
    const OnboardingBtnContainerComponent = GoogleLoginForInternalUser
      ? React.Fragment
      : OnboardingButtonContainer;
    const { errorMsg } = useAppSelector((state) => ({
      googleSignedIn: state.auth.signedIn,
      errorMsg: state.auth.error,
    }));

    const schema = Yup.object()
      .strict(true)
      .shape({
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        password: GetPasswordValidation({
          isPortalLogin,
          isAdminLogin,
        }),
      });

    const onSubmit = async (values: OnboardingFormValues) => {
      const parsedValues = {
        ...values,
        email:
          // for backward compatibility do not lowercase email if login
          // only in onboarding to create new portal lowercase email
          !isPortalLogin
            ? removeSpaceToLowerCase(values.email)
            : removeSpaceFromString(values.email), // update email to remove space and lowercase
      };

      const isSuccess = await handleSubmitDone(parsedValues);

      if (isSuccess && !trackedEvents[OnboardingTrackEventName.Lead]) {
        TrackEvent('Onboarding - Portal Account Created', {
          email: parsedValues.email,
        });
        onAnalyticsEventTracked(OnboardingTrackEventName.Lead);
      }
    };

    const renderPasswordInputLabel = () =>
      !isPortalLogin ? I18n.get('Set a password') : '';

    const TextFieldComponent = GoogleLoginForInternalUser
      ? SharpOutlinedTextField
      : BaseTextField;

    /**
     * This function is used to get the error text to display
     * @param errorsState formik errors state
     * @returns
     */
    const getFormErrorText = (
      errorsState: FormikErrors<OnboardingFormValues>,
      touchedState: FormikTouched<OnboardingFormValues>,
    ) => {
      const passwordTouched = getIn(touchedState, 'password');
      const emailTouched = getIn(touchedState, 'email');
      const passwordError = getIn(errorsState, 'password');
      const emailError = getIn(errorsState, 'email');
      const authError = errorMsg;

      // when both password and email are invalid
      // render both errors
      if (passwordError && emailError && passwordTouched && emailTouched) {
        return `The email and password combination provided is invalid.`;
      }

      if (passwordError && passwordTouched) {
        return passwordError;
      }
      if (emailError && emailTouched) {
        return emailError;
      }

      if (authError) {
        return authError;
      }
      return null;
    };

    // customState is the state that we set in the oauth sign in flow
    // In our implementation, this is used to save the redirects that we want
    // to do after the flow
    const [customState, setCustomState] = React.useState('');

    // This useEffect sets the customState to track the what the oauthCompleteURL will be.
    // This value is passed as the customState to federatedSignIn.
    // In the signup flow, we redirect to the second step of the flow.
    React.useEffect(() => {
      if (isPortalLogin) {
        return;
      }

      // when this is a signup, we want to redirect to the signup page again
      // since, the user is logged in now, the next step will be called
      // Also retain the template_id if it is present.
      let cs = `signup?step=company-setup&authSource=Google`;
      if (query.template_id) {
        cs += `&${UrlUtils.FormatUrlQueryToString({
          template_id: query.template_id,
          template_name: query.template_name,
        })}`;
      }
      setCustomState(cs);
    }, [query, isPortalLogin]);

    return (
      <>
        {!isSignUpDisabled && (
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            innerRef={
              ref as MutableRefObject<FormikProps<OnboardingFormValues>>
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              isSubmitting,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                data-testid="testingform"
              >
                <FormInputsContainer>
                  {GoogleLoginForInternalUser && (
                    <>
                      <GoogleAuthButton
                        onClick={() => {
                          SignInWithGoogle(customState);
                        }}
                        noBorderRadius
                      />
                      <RowDivider mt={3.25} mb={0.75}>
                        <BaseTypography
                          style={{
                            color: GraySmall,
                          }}
                          fontType="12Medium"
                        >
                          OR
                        </BaseTypography>
                      </RowDivider>
                    </>
                  )}
                  <TextFieldComponent
                    sizeVariant="tall"
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'emailInput',
                    }}
                    id="login-username"
                    autoFocus={
                      !GoogleLoginForInternalUser && !initialValues.email
                    }
                    fullWidth
                    type="text"
                    key="email"
                    name="email"
                    placeholder={
                      GoogleLoginForInternalUser
                        ? I18n.get('Work email')
                        : undefined
                    }
                    onBlur={handleBlur}
                    onInput={handleChange}
                    value={
                      values.email ? removeSpaceFromString(values.email) : ''
                    }
                    label={I18n.get('Work email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={
                      (!GoogleLoginForInternalUser &&
                        touched.email &&
                        errors.email) ||
                      ' '
                    }
                    autoComplete="off"
                  />

                  {!isAdminLogin && (
                    <div>
                      {!renderPasswordInputLabel() &&
                        !GoogleLoginForInternalUser && (
                          <div className={classes.passwordInputLabel}>
                            <BaseTypography fontType="13Medium">
                              {I18n.get('Password')}
                            </BaseTypography>
                            <Button
                              href={`?step=${AUTH_STATES.FORGOT_PASSWORD}`}
                              tabIndex="-1" // should not be focusable via sequential keyboard navigation.
                            >
                              <BaseTypography
                                fontType="13Regular"
                                style={{ color: GraySmall }}
                              >
                                Forgot password?
                              </BaseTypography>
                            </Button>
                          </div>
                        )}
                      <TextFieldComponent
                        sizeVariant="tall"
                        variant="outlined"
                        label={
                          GoogleLoginForInternalUser
                            ? I18n.get('Password')
                            : renderPasswordInputLabel()
                        }
                        id="login-password"
                        fullWidth
                        type="password"
                        InputProps={{
                          'data-testid': 'passwordInput',
                        }}
                        key="password"
                        name="password"
                        placeholder={
                          GoogleLoginForInternalUser
                            ? I18n.get('Password')
                            : undefined
                        }
                        onBlur={handleBlur}
                        onInput={handleChange}
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        // the password validation errors should display in the error callout when flag is enabled
                        helperText={
                          !GoogleLoginForInternalUser &&
                          ((touched.password && errors.password) || '')
                        }
                        autoComplete="off"
                        autoFocus={Boolean(initialValues.email)}
                      />
                      {GoogleLoginForInternalUser && (
                        <OnboardingFormError
                          errorText={getFormErrorText(errors, touched)}
                        />
                      )}
                    </div>
                  )}
                  <OnboardingBtnContainerComponent>
                    <OnboardingButton
                      type="submit"
                      isLoading={isSubmitting}
                      data-testid="submit-button"
                      color="primary"
                      variant="contained"
                      fullWidth
                      htmlId="create-account-submit-button"
                    >
                      {isPortalLogin || isAdminLogin
                        ? 'Sign in'
                        : 'Create an account'}
                    </OnboardingButton>
                  </OnboardingBtnContainerComponent>
                </FormInputsContainer>
              </form>
            )}
          </Formik>
        )}
        <OnboardingLoginFormFooterActions
          showOnboardingLoginActions={showOnboardingLoginActions}
          hidePolicy={isSignUpDisabled}
        />
      </>
    );
  },
);
