import React, { useContext } from 'react';
import { I18n } from 'aws-amplify';
import { createStyles, makeStyles } from '@material-ui/core';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { FlagsContext } from 'src/context';
import history from 'src/history';

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};
type Either<T, U> = Only<T, U> | Only<U, T>;
interface AuthLinkActionButton {
  onClick: () => void;
}
interface AuthLinkActionWithHref {
  href: string;
}

// auth link button should have href or onClick prop provided.
type Props = Either<AuthLinkActionWithHref, AuthLinkActionButton> & {
  linkText: string;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: GraySmall,
      cursor: 'pointer',
      '&:hover': {
        color: BlackHeadings,
      },
    },
  }),
);

export const AuthLinkActionButton: React.FC<Props> = ({
  className,
  onClick,
  linkText,
  href,
}) => {
  const classes = useStyles();
  const { GoogleLoginForClients } = useContext(FlagsContext);

  const handleClick = () => {
    if (href) {
      history.push(href);
    }
    onClick?.();
  };
  return (
    <div
      className={className}
      style={{
        justifyContent: GoogleLoginForClients ? 'center' : 'space-between',
      }}
    >
      <BaseTypography
        component="a"
        onClick={handleClick}
        className={classes.link}
      >
        {I18n.get(linkText)}
      </BaseTypography>
    </div>
  );
};
