import React, { useContext } from 'react';
import { MenuItem } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { OnboardingFormProps } from 'src/components/Onboarding/OnboardingForms/onboardingFormTypes';
import {
  INDUSTRY_LIST,
  COMPANY_SIZE_LIST,
  CLIENT_TYPES,
  CLIENT_TYPE_COMPANY,
  CLIENT_COUNT_LIST,
} from 'src/constants';
import { OnboardingButtonContainer } from 'src/components/Onboarding/OnboardingForms/OnboardingButtonContainer';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/components/TextField';
import { TrackEvent } from 'src/utils/AnalyticsUtils';
import OnboardingButton from 'src/components/Onboarding/OnboardingButton';
import { FormInputsContainer } from 'src/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import { FlagsContext } from 'src/context';
import { OnboardingFormError } from 'src/components/Onboarding/OnboardingForms/OnboardingFormError';
import { getFormErrorText } from 'src/components/Onboarding/OnboardingForms/CreatePortalForm';

const schema = Yup.object().shape({
  industry: Yup.string().required('Industry is required'),
  industryCustom: Yup.string().when('industry', {
    is: 'other',
    then: Yup.string()
      .trim()
      .min(4, 'Must have at least 4 characters')
      .max(50, 'Must not exceed 50 characters')
      .required('Industry is required'),
  }),
  companySize: Yup.string().required('Company size is required'),
  clientCount: Yup.string().required('Client count is required'),
  clientType: Yup.string().required('Client type is required'),
});

export const CompanyProfileForm: React.FC<OnboardingFormProps> = ({
  initialValues,
  handleSubmitDone,
}) => {
  const [otherIsSelected, setOtherIsSelected] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const TextFieldComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;

  const OnboardingBtnContainerComponent = GoogleLoginForInternalUser
    ? React.Fragment
    : OnboardingButtonContainer;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const valuesObj = { ...values };
        if (otherIsSelected) {
          valuesObj.industry = values.industryCustom;
        }
        setIsSubmitting(true);
        const isSuccess = await handleSubmitDone(valuesObj);
        if (isSuccess) {
          TrackEvent('Onboarding - Company Info Submitted', {
            industry: values.industry,
            industry_other: values.industryCustom,
            role: values.role,
            company_size: values.companySize,
            client_count: values.clientCount,
            client_type: values.clientType,
            default_channel_type:
              values.clientType === CLIENT_TYPE_COMPANY ? 'company' : 'client',
            enable_companies:
              values.clientType === CLIENT_TYPE_COMPANY ? 'True' : 'False',
          });
        }
        setIsSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormInputsContainer>
            <TextFieldComponent
              sizeVariant="tall"
              select
              variant="outlined"
              name="industry"
              error={Boolean(touched.industry && errors.industry)}
              helperText={
                !GoogleLoginForInternalUser &&
                touched &&
                errors &&
                touched.industry &&
                errors.industry
                  ? errors.industry
                  : ' '
              }
              label="Which industry are you in?"
              value={values.industry}
              onChange={(e) => {
                setOtherIsSelected(e.target.value === 'other');
                handleChange(e);
              }}
              inputProps={{
                'data-testid': 'industry-dropdown',
              }}
              onBlur={handleBlur}
              fullWidth
            >
              {INDUSTRY_LIST.map((industryOption) => (
                <MenuItem
                  value={industryOption.value}
                  key={industryOption.id}
                  data-testid="industry-item"
                >
                  {industryOption.label}
                </MenuItem>
              ))}
            </TextFieldComponent>
            {otherIsSelected && (
              <TextFieldComponent
                sizeVariant="tall"
                id="industryCustom"
                name="industryCustom"
                type="text"
                label="Please specify"
                variant="outlined"
                value={values.industryCustom}
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                error={Boolean(touched.industryCustom && errors.industryCustom)}
                helperText={
                  (!GoogleLoginForInternalUser &&
                    touched.industryCustom &&
                    errors.industryCustom) ||
                  ' '
                }
                autoFocus
              />
            )}
            <TextFieldComponent
              sizeVariant="tall"
              select
              variant="outlined"
              name="companySize"
              error={Boolean(touched.companySize && errors.companySize)}
              helperText={
                !GoogleLoginForInternalUser &&
                touched &&
                errors &&
                touched.companySize &&
                errors.companySize
                  ? errors.companySize
                  : ' '
              }
              label="How large is your company?"
              value={values.companySize}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            >
              {COMPANY_SIZE_LIST.map((sizeOption) => (
                <MenuItem value={sizeOption.value} key={sizeOption.id}>
                  {sizeOption.label}
                </MenuItem>
              ))}
            </TextFieldComponent>
            <TextFieldComponent
              sizeVariant="tall"
              select
              variant="outlined"
              name="clientCount"
              error={Boolean(touched.clientCount && errors.clientCount)}
              helperText={
                !GoogleLoginForInternalUser &&
                touched &&
                errors &&
                touched.clientCount &&
                errors.clientCount
                  ? errors.clientCount
                  : ' '
              }
              label="How many clients do you have?"
              value={values.clientCount}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            >
              {CLIENT_COUNT_LIST.map((sizeOption) => (
                <MenuItem value={sizeOption.value} key={sizeOption.id}>
                  {sizeOption.label}
                </MenuItem>
              ))}
            </TextFieldComponent>
            <TextFieldComponent
              sizeVariant="tall"
              select
              variant="outlined"
              name="clientType"
              error={Boolean(touched.clientType && errors.clientType)}
              helperText={
                !GoogleLoginForInternalUser &&
                touched &&
                errors &&
                touched.clientType &&
                errors.clientType
                  ? errors.clientType
                  : ' '
              }
              label="Do you primarily serve companies or individuals?"
              value={values.clientType}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            >
              {CLIENT_TYPES.map((clientType) => (
                <MenuItem value={clientType.value} key={clientType.id}>
                  {clientType.label}
                </MenuItem>
              ))}
            </TextFieldComponent>

            {GoogleLoginForInternalUser && (
              <OnboardingFormError
                errorText={getFormErrorText(errors, touched)}
              />
            )}

            <OnboardingBtnContainerComponent>
              <OnboardingButton
                type="submit"
                data-testid="submit-button"
                color="primary"
                variant="contained"
                fullWidth
                htmlId="company-profile-submit-button"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Launch workspace
              </OnboardingButton>
            </OnboardingBtnContainerComponent>
          </FormInputsContainer>
        </form>
      )}
    </Formik>
  );
};
