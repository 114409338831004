import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import { AdminCardShadow } from 'src/theme/shadows';
import { white } from 'src/theme/colors';

const ONBOARDING_FORM_WIDTH = 416;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5, 3.5),
    borderRadius: 0,
    boxShadow: AdminCardShadow,
    backgroundColor: white,
    width: ONBOARDING_FORM_WIDTH,
    margin: 'auto',
    // on mobile screen, the onboarding card has no
    // border and shadow
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      boxShadow: 'none',
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

interface OnboardingCardProps {
  className?: string;
}
export const OnboardingFormCard: React.FC<OnboardingCardProps> = ({
  children,
  className,
}) => {
  const classes = useStyles();

  return (
    <RegularCardBox className={clsx(classes.root, className)}>
      {children}
    </RegularCardBox>
  );
};
